'use strict';

$(document).ready(function() {

  //--------------------------------------//
  //           GLOBAL VARIABLES           //
  //--------------------------------------//
  var $window        = $(window),
      $html          = $('html'),
      $window_width  = $window.width();
  
  // get window width on resize
  $window.on('resize', function() {
    $window_width = $window.width();
  });


  /**
   * toggle class on mobile hamburger click
   * 
   * event click
   */
  // set variables
  var navbar = $('.navbar-collapsing');
  
  $('.navbar-toggler').on('click', function() {
    
    $(this).toggleClass('open');
    navbar.toggleClass('opened');
  });


   /**
    * scroll to element on click
    * 
    * event click
    */
  $('.scroll-to').on('click', function() {
    // set variables
    var target = $(this).attr('href'),
        topOffset = 30;

    if($window_width <= 991) {
      topOffset = 20;
    }

    if($(target).length) {
      $html.animate({
        scrollTop: $(target).offset().top - topOffset
      }, 250);
    }
  });


  /**
   * form validation
   * 
   * event click
   */
  $('.form-submit').on('click', function(e) {
    // set variables
    var form = $(this).closest('.form-validation'),
        formInput = form.find('.required');

    formInput.each(function() {
      $(this).val() == '' ? ( $(this).next('.form-error').show(), e.preventDefault() ) : $(this).next('.form-error').hide();
    });
  });

});